<template>
  <div class="bitts-simple-date-filter">
    <div class="bitts-simple-date-filter__range-picker">
      <RangePicker
        :value="selectedDates.length ? selectedDates : undefined"
        :open="true"
        :get-popup-container="getPopupContainer"
        separator="-"
        @calendar-change="onCalendarChange"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { RangePicker } from 'ant-design-vue';
import dayjs from 'dayjs';
import { ref, watchEffect } from 'vue';

const { value = [] } = defineProps<{
  value?: [string, string] | [];
}>();

const emit =
  defineEmits<(e: 'date-changed', range: [string, string] | null) => void>();

const selectedDates = ref<[dayjs.Dayjs, dayjs.Dayjs] | []>(
  value.map((d) => dayjs(d, 'YYYY/MM/DD')) as [dayjs.Dayjs, dayjs.Dayjs],
);
function getPopupContainer(trigger: HTMLElement) {
  return trigger?.parentElement as HTMLElement;
}

function onCalendarChange(
  range: null | [dayjs.Dayjs, dayjs.Dayjs] | [string, string],
) {
  // Range is null when clicking the clear icon inside the date field
  if (range === null) {
    emit('date-changed', null);
    selectedDates.value = [];
    return;
  }

  const [from, to] = range as [dayjs.Dayjs, dayjs.Dayjs];
  if (from) selectedDates.value[0] = from;
  if (to) selectedDates.value[1] = to;
  if (!!from && !!to)
    emit('date-changed', [from.toISOString(), to.toISOString()]);
}

watchEffect(() => {
  selectedDates.value = value.map((d) => dayjs(d, 'YYYY/MM/DD')) as [
    dayjs.Dayjs,
    dayjs.Dayjs,
  ];
});
</script>

<style scoped lang="pcss">
h2 {
  @apply font-bold text-neutral-text;
}
.bitts-simple-date-filter {
  @apply p-12 pb-0 flex flex-col gap-12 shadow-component;
}
.bitts-simple-date-filter__range-picker {
  @apply min-h-[320px] bg-white rounded-8 w-[564px] flex flex-col;
}
:deep(.ant-picker-dropdown-range) {
  @apply !p-0;
}

:deep(.ant-picker-panel-container) {
  @apply shadow-none;
}

:deep(.ant-picker-range-arrow) {
  @apply !hidden;
}
</style>
